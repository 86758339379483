import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { connect as connectFela } from "react-fela"

import Button from "components/Button"
import Container from "components/container"
import H3 from "components/typography/H3"
import Link from "components/Link"
import P from "components/typography/P"
import TitleLink from "components/TitleLink"
import UnderlinedTitle from "components/UnderlinedTitle"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"

const Block = ({ rules, styles, title, content, circle, pageColor }) => {
  const { allSanityPartner: { edges: node } } = useStaticQuery(graphql`
    query {
      allSanityPartner {
        edges {
          node {
            name
            description
            link {
              ...sanityLinkFrag
            }
          }
        }
      }
    }
  `)

  return (
    <Container extend={{styles: rules.container}} noPaddings fullscreen tag={'section'}>
      {circle && <div className={styles.circle} />}
      <div className={styles.title}><UnderlinedTitle extend={{styles: rules.overrideTitle}}>{title}</UnderlinedTitle></div>

      <div className={styles.partners}>
        {node && node.map(({node: partner}, index) => {
          return (
            <div key={index} className={styles.partnerContainer}>
              <div className={styles.partner}>
                <TitleLink to={partner.link}>
                  <H3 extend={{styles: rules.partnerTitle}}>{partner.name}</H3>
                </TitleLink>
                <P extend={{styles: rules.partnerParagraph}}version="info">{partner.description}</P>
                <div className={styles.partnerLink}>
                  <Link bgColor={colors[`color${pageColor}200`]} to={partner.link}>Learn more</Link>
                </div>
              </div>
            </div>)
        })}
      </div>
    </Container>
  )
}

/*
 * Only style the inside, the asymertric alignment should be dealt with in the parent components
 */
const styles = (props) => ({
  container: {
    /* border: '1px solid black', */
  },
  overrideTitle: {
    zIndex: 1,
    marginBottom: '0 !important',
  },
  title: {
    display: 'flex',
    ...fluidValues({marginBottom: [48, 90]}),
  },
  circle: {
    backgroundColor: (props.pageColor ? props.pageColor : colors.colorCanvas500),
    borderRadius: '50%',
    height: '53vw',
    left: '10vw',
    position: 'absolute',
    top: '14vh',
    width: '53vw',
    zIndex: 0,
  },
  contentContainer: {
    maxWidth: 711,
    width: '100%',
    extend: {
      ...fluidValues({marginBottom: [60, 220]}),
    }
  },
  content: {
    position: 'relative',

    '> *': {
      marginTop: 0,
    },
  },
  large: {
    ...fluidValues({marginBottom: [24, 32]}),
  },
  button: {
    boxSizing: 'border-box',
    marginTop: 32,
    maxWidth: 400,
    textAlign: 'center',
    width: '100%',
  },
  partners: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  partnerContainer: {
    flex: '0 0 50%',
    minWidth: 260,
    position: 'relative',
    ...fluidValues({marginBottom: [72, 122]}),

    phoneOnly: {
      flex: '1 0 100%'
    },
    desktopAndUp: {
      flex: '0 0 33%',
    }
  },
  partnerTitle: {
    extend: {
      marginBottom: 24,
    }
  },
  partnerParagraph: {
    marginBottom: 24,
  },
  partner: {
    ...fluidValues({paddingBottom: [24, 32]}),
    borderBottom: '1px solid #000000',
    height: '100%',
    maxWidth: '82%',

    phoneOnly: {
      maxWidth: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  partnerLink: {
    marginTop: 'auto',
  }
})

Block.defaultProps = {
  rules: {},
  styles: {},
  title: '',
  /* content: null, */
  circle: false,
  pageColor: '',
}

Block.propTypes = {
  rules: PropTypes.object,
  styles: PropTypes.object,
  title: PropTypes.string,
  /* content: PropTypes.array, */
  circle: PropTypes.bool,
  pageColor: PropTypes.string,
}

export default connectFela(styles)(Block)
