import React from "react"
import { connect as connectFela } from "react-fela"
import BlockContent from '@sanity/block-content-to-react'
import { graphql } from "gatsby"

import Container from "components/container"
import P from "components/typography/P"
import { commonMarks } from "utilities/serializers"
import { serializers as linkSerializers } from "components/BaseLink.js"
import quote from "images/quote.svg"
import AuthorLine from "components/AuthorLine"

const serializers = ({styles, rules}) => {
  return {
    block: (props) => {
      return <P version={'quote'}>{props.children}</P>
    },
    marks: {
      ...linkSerializers(styles, rules),
      ...commonMarks({styles, rules}),
    }
  }
}

/* Component */
export default connectFela(() => ({
  containerTop: {
    maxWidth: 731,
    marginLeft: 0
  },
  container: {
    justifyContent: 'end',
    borderLeft: '1px solid black',
  },
  contentPart: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    maxWidth: '85%'
  },
  icon: {
    width: 26,
    marginBottom: 21,
    marginRight: 'auto'
  },
  AuthorLine: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    maxWidth: '85%'
  }
}))(({
  styles,
  rules,
  content,
  name,
  position,
  company
}) => {
  return <Container noPadding extend={{styles: rules.containerTop}}>
           <Container flex noPadding extend={{styles: rules.container}}>
             <div className={styles.contentPart}>
               <img className={styles.icon} src={quote} alt={""} />
               <BlockContent
                 className={styles.content}
                 blocks={content}
                 serializers={serializers({rules, styles})} />
             </div>
           </Container>
           <AuthorLine
             name={name}
             position={position}
             company={company}
             extend={{container: rules.AuthorLine}} />
         </Container>
})

export const quoteContentFrag = graphql`
fragment sanityQuotedContentFrag on SanityQuotedContent {
    _type
    _rawContent
    name
    position
    company
}`
