import React from "react"
import { connect as connectFela } from "react-fela"
import BlockContent from '@sanity/block-content-to-react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import * as R from "ramda"
import { graphql } from "gatsby"

import colors from "theme/colors"
import Container from "components/container"
import P from "components/typography/P"
import H2 from "components/typography/H2"
import H3 from "components/typography/H3"
import H5 from "components/typography/H5"
import fluidValues from "utilities/fluidValues"
import { commonMarks } from "utilities/serializers"
import { serializers as linkSerializers } from "components/BaseLink"
import AngleDown from "components/svg/angleDown"
import slugify from "utilities/slugify"
import Ul from "components/typography/UL"
import ViewButton from "components/ViewButton"

const serializers = ({styles, rules}) => {
  return {
    list: props => ( <Ul {...props} type={props.type} extend={{styles: rules.list}}>{props.children}</Ul> ),
    listItem: props => {
      return <P tag={'li'} version={props.node?.listItem === 'dash' ? "list-light" : "list"}>{props.children}</P>
    },
    block: (props) => {
      return <P version={'body'}>{props.children}</P>
    },
    marks: {
      ...linkSerializers(styles, rules),
      ...commonMarks({styles, rules}),
    }
  }
}

/* Component */
export default connectFela(() => ({
  list: {
    ...fluidValues({
      paddingLeft: [21, 110],
    }),
    margin: '24px auto',
    maxWidth: 650,

    tabletPortraitAndUp: {
      margin: '32px auto',
    },
    '& > li': {
      marginLeft: 13
    }
  },
  container: {
    flexDirection: 'column'
  },
  angleDown: {
    ...fluidValues({
      width: [10, 33],
      height: [7, 23]
    })
  },
  subtitle: {
    ...fluidValues({
      marginTop: [-12, -28],
      marginBottom: [0, 14]
    }),
  },
  scenario: {
    display: 'flex',
    position: 'relative',
    phoneOnly: {
      flexWrap: 'wrap',
    }
  },
  contentPart: {
    ...fluidValues({
      marginBottom: [48, 90]
    })
  },
  topArtefact: {
    phoneOnly: {
      display: 'none'
    },
    position: 'absolute',
    top: 0,
    left: 0,
    ...fluidValues({
      height: [12, 56],
      width: [40, 189],
      marginTop: [-6, -28]
    }),
    zIndex: 1
  },
  blackPart: {
    backgroundColor: colors.colorConsole500,
    height: '50%',
    width: '100%',
  },
  dots: {
    height: '50%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyCenter: 'start',
    ...fluidValues({
      paddingLeft: [5, 10]
    })
  },
  dot: {
    backgroundColor: colors.colorConsole500,
    ...fluidValues({
      height: [5, 20],
      width: [5, 20],
      marginLeft: [5, 12]
    }),
    borderRadius: '50%'
  },
  image: {
    maxWidth: 1140,
    maxHeight: 591,
    display: 'none',
    tabletPortraitAndUp: {
      display: 'block',
    },
    ...fluidValues({
      paddingLeft: [20, 300],
    })
  },
  mobileimage: {
    maxWidth: 591,
    maxHeight: 591,
    display: 'block',
    width: '100vw',
    paddingLeft: '0',
    tabletPortraitAndUp: {
      ...fluidValues({
        width: [100, 591],
        paddingLeft: [20, 300],
      }),
    }
  },
  downloadPart: {
    phoneOnly: {
      width: '100%',
      ...fluidValues({
        paddingRight: [20, 300],
        paddingLeft: [20, 300],
        marginTop: [32, 60]
      }),
      display: 'flex',
      flexWrap: 'wrap',
    },
    tabletPortraitAndUp: {
      maxWidth: 300,
      ...fluidValues({
        marginLeft: [39, 65]
      })
    }
  },
  downLoadPartTitle: {
    width: '100%'
  },
  downLoadPartSubTitle: {
    phoneOnly: {
      maxWidth: 230
    }
  },
  downloadButton: {
    phoneOnly: {
      marginLeft: 'auto',
      paddingTop: 4
    },
    '& > a': {
      display: 'inline-block',
    }
  },
  yourPart: {
    maxWidth: 842,
    borderBottom: '1px solid black',
    position: 'relative',
    paddingLeft: 0,
    marginTop: -80,
    ...fluidValues({
      marginLeft: [20, 408],
      height: [112, 210],
      width: [280, 842]
    }),
    phoneOnly: {
      width: "100% !important",
      marginLeft: '0 !important'
    }
  },
  yourWhitePart: {
    boxSizing: 'border-box',
    backgroundColor: 'white',
    height: '100%',
    width: '50%',
    '& > h5': {
      whiteSpace: 'normal'
    },
    borderBottomColor: 'black',
    borderBottomStyle: ' solid',
    ...fluidValues({
      paddingTop: [16, 32],
      paddingLeft: [21, 40],
      paddingRight: [20, 20],
      borderBottomWidth: [10, 24]
    }),
    phoneOnly: {
      maxWidth: 230,
      width: '100%',
    }
  }
}))(({ styles, rules, title, subtitle, content, scenario, index }) => {
  return <Container flex noPadding extend={{styles: rules.container}} id={slugify(title)}>
           <div className={styles.contentContainer}>
             <H2>{title}{' '}<AngleDown className={styles.angleDown}/></H2>
             <div className={styles.subtitle}>
               <P version={'quote'}>{subtitle}</P>
             </div>
             <div className={styles.contentPart}>
               <BlockContent
                 className={styles.content}
                 blocks={content}
                 serializers={serializers({rules, styles})} />
             </div>
           </div>

           <div className={styles.scenario}>
             <div className={styles.topArtefact}>
               <div className={styles.dots}>
                 {R.range(0, index).map((i) => <div key={'dot' + index + i} className={styles.dot}></div>)}
               </div>
               <div className={styles.blackPart}></div>
             </div>
             {false && <div className={styles.image}><Img image={scenario.image.asset.gatsbyImageData} alt={title} /></div>}
             {scenario.mobileimage && scenario.mobileimage.asset && <div className={styles.mobileimage} ><Img image={scenario.mobileimage.asset.gatsbyImageData} alt={title} /></div>}

             {scenario.scenarioDocument &&
              <div className={styles.downloadPart}>
                <H5 extend={{styles: rules.downLoadPartTitle}}>{title}</H5>
                <H3 extend={{styles: rules.downLoadPartSubTitle}}>Explore a scenario</H3>

                {scenario.scenarioDocument &&
                 <div className={styles.downloadButton}>
                   <ViewButton
                     to={scenario.scenarioDocument.file}
                     extend={{styles: rules.downloadButton}} />
                 </div>}
              </div>}
           </div>
         </Container>
})

export const consultSectionFrag = graphql`
fragment sanityConsultSectionFrag on SanityConsultSection {
     _type
     title
     subtitle
      _rawContent(resolveReferences: {maxDepth: 10})
     scenario {
       image {
         asset {
           gatsbyImageData(layout: CONSTRAINED, width:  1440)
         }
       }
       mobileimage {
         asset {
           gatsbyImageData(layout: CONSTRAINED, width:  1440)
         }
       }
       scenarioDocument {
         title
         file {
           asset {
             localFile {
               publicURL
               name
               internal {
                 contentDigest
               }
             }
             originalFilename
           }
         }
       }
     }
}`
