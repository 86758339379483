import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { connect as connectFela } from "react-fela"

import H4 from "components/typography/H4"
import UnderlinedTitle from "components/UnderlinedTitle"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"
import fluidDimensions from "utilities/fluidDimensions"

const Block = ({ rules, styles, title, filters, featured, circle }) => {
  const { allSanityClient: { edges: nodes } } = useStaticQuery(graphql`
    query {
      allSanityClient {
        edges {
          node {
            name
            tags
          }
        }
      }
    }
  `)
  const clients = nodes.filter(({node: { name }}) => !['Google', 'Kiwibank', 'Miro', 'Deloitte'].includes(name))
  return (
    <>
      <div className={styles.singleTagTitle}><UnderlinedTitle extend={{styles: rules.overrideTitle}}>{title}</UnderlinedTitle></div>
      <div className={styles.clients}>
        {clients.map(({node: {name}}, index) => {
          return (<H4 tag={'p'} extend={{styles: rules.client}} key={index}>{name}</H4>)
        })}
      </div>
    </>
  )
}

/*
 * Only style the inside, the asymertric alignment should be dealt with in the parent components
 */
const styles = (props) => ({
  title: {
    display: 'flex',
    ...fluidValues({marginBottom: [48, 90]}),
  },
  singleTagTitle: {
    display: 'flex',
    ...fluidValues({marginBottom: [16, 26]}),
  },
  overrideTitle: {
    marginBottom: '0 !important',
  },
  filters: {
    ...fluidValues({marginBottom: [41, 90]}),
    borderBottom: '1px solid #000000',
    cursor: ['move', 'grab', '-moz-grab', '-webkit-grab'],
    display: 'flex',
    maskImage: 'linear-gradient(to right, transparent, black 20px, black 90%, transparent)',
    overflow: 'hidden',

    onActive: {
      cursor: ['grabbing', '-moz-grabbing', '-webkit-grabbing'],
    }
  },
  filter: {
    flex: '0 0 25%',
    cursor: 'pointer',
    color: '#000000',
    textAlign: 'center',
    paddingBottom: 16,
    whiteSpace: 'pre',
    marginLeft: 24,
    marginRight: 24,
    userSelect: 'none',
  },
  active: {
    flex: '0 0 25%',
    cursor: 'pointer',
    color: '#000000',
    textAlign: 'center',
    paddingBottom: 16,
    whiteSpace: 'pre',
    marginLeft: 24,
    marginRight: 24,
    borderBottom: '6px solid #000000',
    userSelect: 'none',
  },
  clients: {
    display: 'flex',
    flexWrap: 'wrap',
    extend: {
      ...fluidValues({marginBottom: [-12, -24]}),
    }
  },
  client: {
    flex: '0 0 25%',
    marginTop: 0,

    phoneOnly: {
      flex: '0 0 50%',
    },
  },
  featureds: {
    display: 'flex',
    flexWrap: 'wrap',
    zIndex: 1,
    position: 'relative',
  },
  featuredContainer: {
    flex: '0 0 50%',
    marginRight: 'auto',
    minWidth: 260,
    position: 'relative',
    ...fluidValues({marginTop: [48, 90]}),

    phoneOnly: {
      flex: '1 0 100%'
    },
    desktopAndUp: {
      flex: '0 0 33%',
    }
  },
  featured: {
    height: '100%',
    borderBottom: '1px solid #000000',
    maxWidth: '82%',
    phoneOnly: {
      maxWidth: '100%',
    },
  },
  featuredParagraph: {
    marginBottom: 32,

    phoneOnly: {
      marginBottom: 24,
    }
  },
  circle: {
    backgroundColor: colors.colorRoot500,
    borderRadius: '50%',
    left: 0,
    margin: 'auto auto',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 0,
    ...fluidDimensions({width: [461, 838], height: [461, 838]}),

    phoneOnly: {
      width: '100%',
      height: 0,
      padding: '50% 0',
      maxHeight: 'unset',
      maxWidth: 'unset',
      minHeight: 'unset',
      minWidth: 'unset',
    }
  }
})

Block.defaultProps = {
  filters: [],
  rules: {},
  styles: {},
  title: '',
  featured: [],
  circle: false,
}

Block.propTypes = {
  rules: PropTypes.object,
  styles: PropTypes.object,
  title: PropTypes.string,
  featured: PropTypes.array,
  circle: PropTypes.bool,
  filters: PropTypes.array,
}

export default connectFela(styles)(Block)
