import React from "react"
import { connect as connectFela } from "react-fela"

import P from "components/typography/P"
import fluidValues from "utilities/fluidValues"

export default connectFela(({ withBottomBorder }) => ({
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    whiteSpace: 'pre',
    ...fluidValues({
      paddingBottom: [34, 60],
    }),
    '& > p': {
      marginBottom: 0,
    },
    extend: {
      condition: withBottomBorder,
      style: {
        borderBottom: '1px solid black',
      }
    }
  },
  author: {
    fontWeight: 'bold'
  }
}))(({ styles, rules, name, position, company}) => {
  return <div className={styles.container}>
           <P
             extend={{styles: rules.author}}
             version={'info'}>{name}</P>{position && <>{', '}<P version={'info'}>{position}</P></>}{company && <>{', '}<P version={'info'}>{company}</P></>}
         </div>
})
