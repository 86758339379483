import React from "react"
import { graphql } from "gatsby"
import { connect as connectFela } from "react-fela"

import ClientsBlock from "components/ClientsBlock"
import ColorContext from "context/ColorContext"
import ConsultSection from "./ConsultSection"
import Container from "components/container"
import H2 from "components/typography/H2"
import H4 from "components/typography/H4"
import Header from './Header'
import Layout from "components/layout"
import P from "components/typography/P"
import PartnersBlock from "components/PartnersBlock"
import QuotedContent from "./QuotedContent"
import Scroll from 'react-scroll'
import Share from "components/Share"
import { getHexColor } from "theme/colors"
import fluidValues from "utilities/fluidValues"
import slugify from "utilities/slugify"
import QuickView from "../aus-page/quick-view"

const navActive = {
  '@media (min-width: 1000px)': {
    onAfter: {
      position: 'absolute',
      content: "''",
      backgroundColor: 'black',
      display: 'block',
      width: '110%',
      height: 11,
      bottom: -11,
    }
  }
}

const navActiveMobile = {
  '@media (max-width: 1000px)': {
    onAfter: {
      position: 'absolute',
      content: "''",
      backgroundColor: 'black',
      display: 'block',
      ...fluidValues({
        left: [-43, -110],
      }),
      width: 10,
      height: '100%',
    }
  }
}
const isCurrentSection = (item) => typeof window !== 'undefined' && window.location.href.includes(slugify(item))
const scrollTo = (anchor) => Scroll.scroller.scrollTo(anchor, {smooth: true,})

export default connectFela(({data: { sanityConsultPage: { color }}}) => ({
  shareContainer: {
    backgroundColor: getHexColor(color, '500'),
    justifyContent: 'end',
    alignItems: 'center',
    ...fluidValues({
      height: [72, 136],
      marginBottom: [60, 120],
      marginTop: [-30, -60]
    })
  },
  socialcontainer: {
    width: '100%',
    maxWidth: 1129,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'right',
  },
  social: {
    ...fluidValues({
      marginLeft: [6, 12]
    }),
  },
  currentSection: {
    ...navActive
  },
  currentSectionMobile: {
    ...navActiveMobile
  },
  navContainer: {
    backgroundColor: getHexColor(color, '500'),
    phoneOnly: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
    ...fluidValues({
      paddingBottom: [32, 60],
    })
  },
  navInnerContainer: {
    boxSizing: 'border-box',
    maxWidth: 1129,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'noWrap',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    ...fluidValues({
      paddingTop: [32, 85],
      paddingLeft: [43, 110],
      paddingRight: [43, 110],
      paddingBottom: [43, 134],
    })
  },
  navHeaders: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > h2:last-child': {
      textAlign: 'left',
      maxWidth: 329,
      width: '100%',
      '@media (max-width: 1000px)': {
        display: 'none',
      }
    }
  },
  navRows: {
    display: 'flex',
    flexDirection: 'column'
  },
  navRow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    minHeight: 77,
    flexWrap: 'wrap',
    borderBottom: '1px solid black',
    '@media (max-width: 1000px)': {
      borderBottom: 'none',
      marginBottom: 21
    },
    '& > h4': {
      marginTop: '0 !important',
      marginBottom: '0 !important',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
      onHover: {
        ...navActive
      },
      '@media (max-width: 999px)': {
        width: '100%'
      },
    },
    '& > p': {
      marginTop: '0 !important',
      marginBottom: '0 !important',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: 329,
      textAlign: 'left',
      '@media (max-width: 1000px)': {
        maxWidth: '100%'
      },
    },
    justifyContent: 'space-between',
  },
  quotedSections: {
    maxWidth: 1440,
    ...fluidValues({
      marginBottom: [26, 140],
    }),
    phoneOnly: {
      width: '100%'
    }
  },
  quotedContent: {
    ...fluidValues({
      width: [280, 731],
      marginLeft: [20, 300],
    })
  },
  consultSections: {
    maxWidth: 1440,
    ...fluidValues({
      marginBottom: [60, 200],
    })
  },
  consultSectionsContentContainer: {
    ...fluidValues({
      paddingLeft: [20, 300],
      width: [280, 731]
    }),
    phoneOnly: {
      width: '100%'
    }
  },
  clients: {
    ...fluidValues({
      marginBottom: [90, 200]
    })
  },
  partners: {
    ...fluidValues({
      marginBottom: [90, 200]
    })
  },
  quick_view: {
    ...fluidValues({
      marginBottom: [60, 120]
    }),
  },
  noMarginBottom: {
    marginBottom: 0
  },
  jump_menu: {
    ...fluidValues({
      marginBottom: [60, 120]
    })
  },
}))(({
  pageContext,
  path,
  data: {
    jobs,
    settings,
    sanityConsultPage : {
      title,
      meta,
      slug,
      color,
      header,
      quickView,
      consultSections,
      clients,
      _rawPartners,
      partners
    }
  }, rules, styles
}) => {
  let consultSectionsIndex = 0
  return (
    <ColorContext.Provider value={color}>
      <Layout
        pageColor={getHexColor(color, '500')}
        linkColor={getHexColor(color, '200')}
        title={title}
        slug={{ current : path }}
        meta={meta || {}}
        settings={settings}
        jobs={jobs}
        country={pageContext.country}
      >
        {header && <Header
                     {...header}
                     header
                     extend={{styles: rules.header}}
                     copy={header._rawCopy}
                     hidesidebar={false}
                     bgColor={getHexColor(color, '500')}
                     color={(header.fontcolor !== 'Light' ? getHexColor('Console', '500') : getHexColor('Canvas', '50'))}
                   />}

        {consultSections.length > 0 &&
         <Container tag={'section'} extend={{styles: rules.navContainer}}>
           <div className={styles.navInnerContainer}>
             <div className={styles.navHeaders}>
               <H2>What we do</H2>
               <H2>...helps you</H2>
             </div>

             <div className={styles.navRows}>
               {consultSections.length > 0 && consultSections.map((section, index) => {
                 if (section._type === 'consultSection') {
                   return <div className={styles.navRow + " " + (isCurrentSection(section.title) ? styles.currentSectionMobile : '')} key={'consultSection' + index}>
                            <H4 extend={{styles: isCurrentSection(section.title) ? rules.currentSection : ''}}>
                              <a href={"#" + slugify(section.title)} onClick={() => scrollTo(slugify(section.title))}>{section.title}</a>
                            </H4>
                            <P version={'medium'}>{section.subtitle}</P>
                          </div>
                 }
                 return null
               })}
             </div>
           </div>
         </Container>}

        {quickView &&
         <QuickView
           {...quickView}
           color={color}
           pageContext={pageContext}
           extend={{container: rules.quick_view}}
           share={true}
         />}

        {!quickView &&
         <Container flex tag={'section'} extend={{styles: rules.shareContainer}}>
           <div className={styles.socialcontainer}>
          <Share
            extend={{social: rules.social}}
            bgColor={getHexColor(color, '500')}
            url={typeof window !== 'undefined' && window.location.href}
            country={"nz"}
          />
             </div>
         </Container>}

        {consultSections.length > 0 && consultSections.map((section, index) => {
          let sectionComponent = null;

          if (section._type === 'quotedContent') {
            sectionComponent = () => (<Container tag={'section'} noPadding extend={{styles: rules.quotedSections}} key={section._type + index}>
                                        <QuotedContent
                                          {...section}
                                          content={section._rawContent}
                                          extend={{containerTop: rules.quotedContent}}/>
                                      </Container>)
          } else if (section._type === 'consultSection') {
            sectionComponent = () => (<Container tag={'section'} noPadding extend={{styles: rules.consultSections}} key={section._type + index}>
                                        <ConsultSection
                                          {...section}
                                          content={section._rawContent}
                                          index={consultSectionsIndex}
                                          extend={{contentContainer: rules.consultSectionsContentContainer }}
                                        />
                                      </Container>)
            consultSectionsIndex++
          }

          return sectionComponent()
        })}

        {clients &&
         <Container extraLeft withContentArea5 tag={'section'} extend={{styles: rules.clients}}>
           <ClientsBlock title={clients.title} filters={clients.tags} featured={clients.featured} />
         </Container>}

        {partners && <Container extraLeft withContentArea5 tag={'section'} extend={{styles: rules.partners}}>
                       <PartnersBlock
                         pageColor={getHexColor(color, '500')}
                         title={partners.title}
                         content={_rawPartners.content}
                         featured={partners.partners}
                         circle />
                     </Container>}
      </Layout>
    </ColorContext.Provider>
  )
})

export const pageQuery = graphql`
query($country: String!, $slug: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }

    sanityConsultPage(slug: { current: { eq: $slug }}) {
      countrySet
      title
      meta {
        title
        description
        other {
          property
          content
        }
      }
      slug {
        current
      }
      color
      header {
        title
        _rawCopy
        link {
          ...sanityLinkFrag
        }
        category
        image {
          credits
          asset {
            gatsbyImageData(layout: CONSTRAINED, width:  1440)
          }
        }
        fontcolor
      }

      quickView {
          _type
          share
          button_label
          image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width:  1440)
            }
          }
          document {
            title
            file {
              asset {
                localFile {
                  publicURL
                  name
                  internal {
                    contentDigest
                  }
                }
                originalFilename
              }
            }
          }
      }

       consultSections {
         ... on SanityConsultSection {
             ...sanityConsultSectionFrag
         }
         ... on SanityQuotedContent {
             ...sanityQuotedContentFrag
         }
       }

      clients {
        title
        tags
        featured {
          name
          description
        }
      }

      _rawPartners
      partners {
        title
        partners {
          name
          description
          link {
            ...sanityLinkFrag
          }
        }
      }
    }
  }
`
