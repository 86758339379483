/**
 * @fileOverview UL component
 * @name UL.js
 */

import React from "react"
import { connect as connectFela } from "react-fela"

import fluidValues from "utilities/fluidValues"

/**
 * Home page Header UL
 *
 * @param {object} styles Fela styles
 * @param {array} children Children components, usually just text
 *
 * @return {jsx} styled UL
 */
const ul = ({ type, styles, children, ...props }) => {
  return React.createElement(type === 'number' ? 'ol' : 'ul', {className: styles.styles}, children)
}

const getListStyle = (type) => {
  if (type === undefined || type === 'bullet') {
    return {
      listStyleType: 'square',
    }
  }

  if (type === 'nobullet') {
    return {
      listStyleType: 'none',
    }
  }

  if (type === 'numbered') {
    return {}
  }

  if (type === 'dash') {
    return {
      listStyleType: 'none',
      '& > li': {
        position: 'relative',
        ...fluidValues({paddingLeft: [7, 26]}),
      },
      '& > li::before': {
        content: '"—"',
        position: 'absolute',
        ...fluidValues({left: [-14, -25]}),
      }
    }
  }
}

/* Styles */
const styles = ({ type }) => ({
  ...getListStyle(type),
  marginBottom: 0,
  marginTop: 0,
  paddingLeft: '1em',
})

/* Component */
const UL = connectFela({styles})(ul)
export default UL
