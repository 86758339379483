/**
 * @fileOverview Share component
 * @name Share.js
 */

import React from "react"
import { connect as connectFela } from "react-fela"
import { useStaticQuery, graphql } from "gatsby"

import Social from "components/Social"

/**
 * Share icons for articles
 *
 * @param {object} styles Fela styles
 * @param {array} children Children components, usually just text
 *
 * @return {jsx} styled BLOGMETA
 */
const Share = ({ styles, rules, bgColor, url, country }) => {
  const { allSanitySettings: { edges: nodes } } = useStaticQuery(
    graphql`
      query {
        allSanitySettings {
          edges {
            node {
              countrySet
              sharelinks {
                name
                link
              }
            }
          }
        }
      }
    `
  )
  const links = nodes.find(({node}) => node.countrySet === country).node.sharelinks
  return (
    <div className={styles.styles}>
      {links.map(({name, link}, index) => {
        link = link.replace(/\$\{url\}/,url)
        return index < 3 &&
          <Social
            extend={{styles: rules.social}}
            bgColor={bgColor}
            key={`social-${index}`}
            network={name}
            link={link} />
      })}
    </div>
  )
}

/* Styles */
const styles = (props) => ({
  styles: {
    position: 'relative',
    zIndex: 1
  },
  social: {
    marginLeft: 12,
  }
})

/* Component */
export default connectFela(styles)(Share)
