/**
 * @fileOverview A element styled as Button
 * @name Button.js
 */
import React from "react"
import { connect as connectFela } from "react-fela"

import fluidDimensions from "utilities/fluidDimensions"
import colors from "theme/colors"
import Link from "components/BaseLink"

/**
 * P
 *
 * @param {object} styles Fela styles
 * @param {array} children Children components, usually just text
 *
 * @return {jsx} styled A
 */
const viewButton = ({ styles, rules, children, to, onClick, download }) => {
  return <Link
           onClick={onClick}
           download={download}
           alt={"View"}
           to={to}
           extend={{styles: rules.styles}}
         >
           <svg viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
             <circle cx="30" cy="30" r="29.5" stroke="#302739"/>
             <circle cx="30.5" cy="30.5" r="5.5" fill="#302739"/>
             <path d="M30.5 22C24.5 22 18.8333 27.3333 17 30.5C19 33.5 24 38.5 30.5 38.5C37 38.5 42 33 44 30C41.5 26.8333 36.5 22 30.5 22Z" stroke="#302739" strokeWidth="3"/>
           </svg>
         </Link>
}

/* Styles */
const styles = (props) => ({
  cursor: 'pointer',
  ...fluidDimensions({width: [36, 60], height: [36, 60]}),
  '& svg': {
    width: '100%',
    transition: 'fill 0.2s ease',
    fill: 'transparent',

    '& circle:first-child': {
      stroke: props.revert ? "white" : colors.colorConsole500,
    },
    '& path': {
      stroke: props.revert ? "white" : colors.colorConsole500,
    }
  },
  onHover:{
    '& svg': {
      '& path': {
        fill: props.revert ? 'black': 'transparent'
      },
      transition: 'fill 0.2s ease',
      stroke: props.revert ? colors.colorConsole500 : "white",
      fill: props.revert ? '#FFFFFF' : '#000000',

      '& path': {
        stroke: props.revert ? "#000000" : '#FFFFFF',
        fill: 'transparent'
      },
      '& circle:first-child': {
        stroke: props.revert ? "#000000" : '#FFFFFF'
      },
      '& circle:nth-child(2)': {
        fill: props.revert ? colors.colorConsole500 : "white",
        stroke: props.revert ? '#FFFFFF' :"#000000"
      },
    },
  }
})

/* Component */
const ViewButton = connectFela({styles})(viewButton)
export default ViewButton
