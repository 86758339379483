import React, { Fragment, useState } from "react"
import { connect as connectFela } from "react-fela"
import { graphql } from "gatsby"
import { GatsbyImage as Img } from 'gatsby-plugin-image'

import * as playbookUrl from "commands/playbookUrl"
import Button from "components/Button"
import Container from 'components/container'
import fluidValues from "utilities/fluidValues"
import { getHexColor } from "theme/colors"
import Share from "components/Share"

export default connectFela(({ color, share }) => ({
  quickViewContainer: {
    backgroundColor: getHexColor(color, '400'),
    phoneOnly: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
    textAlign: 'center',
    extend: {
      condition: !share,
      style: {
        ...fluidValues({
          paddingBottom: [60, 120],
        })
      }
    }
  },
  button: {
    zIndex: 1,
    backgroundColor: getHexColor(color, '400'),
    ...fluidValues({
      marginTop: [-20, -40]
    })
  },
  imageContainer: {
    maxWidth: 1129,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  image: {
    maxHeight: 383,
  },
  shareContainer: {
    backgroundColor: getHexColor(color, '400'),
    justifyContent: 'end',
    alignItems: 'center',
    ...fluidValues({
      height: [72, 136],
      marginTop: [-10, -20]
    }),
    zIndex: 0,
  },
  socialContainer: {
    width: '100%',
    maxWidth: 1129,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'right',
  },
  social: {
    ...fluidValues({
      marginLeft: [6, 12]
    }),
  },
}))(({
  styles,
  rules,
  color,
  button_label,
  document: { title, file },
  image,
  share,
  pageContext
}) => {
  return <div className={styles.container}>
           <Container tag={'section'} extend={{styles: rules.quickViewContainer}}>
             {image &&
              <div className={styles.imageContainer}>
                <Img className={styles.image} image={image.asset?.gatsbyImageData} alt={title || ''} />
              </div>}

             {file &&
              <Button
                extend={{styles: rules.button}}
                download={playbookUrl.extractFilename(file)}
                to={playbookUrl.buildUrl(file)}>
                {button_label}
              </Button>}
           </Container>

           {share &&
            <Container flex tag={'section'} extend={{styles: rules.shareContainer}}>
              <div className={styles.socialContainer}>
                <Share
                  extend={{social: rules.social}}
                  bgColor={getHexColor(color, '400')}
                  url={typeof window !== 'undefined' && window.location.href}
                  country={pageContext.country}
                />
              </div>
            </Container>}
         </div>
})

export const jumpMenuFrag = graphql`
fragment quickViewFrag on SanityQuickView {
     _type
     share
     button_label
     image {
       asset {
         gatsbyImageData(layout: CONSTRAINED, width:  1440)
       }
     }
     document {
       title
       file {
         asset {
           localFile {
             publicURL
             name
             internal {
               contentDigest
             }
           }
           originalFilename
         }
       }
     }
}`
